import React from 'react';
import LoginComponent from '../Components/LoginComponent';
import SeomonitorPlatform from '../Components/SeomonitorPlatform';

import './Login.css';

const Login = () => {
  return (
      <div className='login-page'>
          <SeomonitorPlatform/>
      </div>
  );
};

export default Login;